import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import {useEffect} from "react";
import ChangeLanguage from "../components/changeLanguage";

const StompPage = ({location}) => {

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);

    return (
        <>
            <Helmet>
                <title>Stomp | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <section className="installation-img-section">
                <img className="" src="/static/img/6_Stomp.jpg" alt=""/>
            </section>

            <section className="site-section site-section--installation">
                <ChangeLanguage location={location}/>
                <div className="content-section" style={ { "--width": "72rem" } }>

                    <h2 className="content-section__title">STOMP</h2>

                    <div className="content-section__text">
                        <p>STOMP is an interactive projection that is activated by pressure pads. By stepping on the pads, the projection generates puffs of colorful smoke.</p>
                    </div>


                    <div className="corners">
                        <p>Step in the center of the pad to release the smoke.</p>

                        <TopLeftCorner className="corners__corner corners__corner_top-left" />
                        <TopRightCorner className="corners__corner corners__corner_top-right" />
                        <BottomRightCorner className="corners__corner corners__corner_bottom-right" />
                        <BottomLeftCorner className="corners__corner corners__corner_bottom-left" />
                    </div>

                    <div className="green">
                        <h2 className="content-section__title">ARTIST — IKONIX</h2>
                        <div className="content-section__text">
                            <p>IKONIX is an Australian-based team of media and production professionals that creates beautiful installations with video, lighting, architecture, and audio. Specializing in ultra high-resolution projection and cutting edge LED technology, IKONIX has brought environments and buildings into an entirely new light, across Australia, the world, and now finally in Hong Kong.</p>
                        </div>
                    </div>

                    <MainSectionLogo className="content-section__logo" />

                </div>
            </section>
        </>
    )
}

export default StompPage;
